import React, { useState, useEffect, useContext } from 'react';
import { CreateUserForm, UserForm, ProfileForm, GatewayForm, VerifyForm, updateUserContext } from './Forms';
import { UserContext } from "../../App";
import { AddressFormNbiot } from '../Forms/AddressForm';
import UsersTable from './SettingDataUsers'
import { NbiotOrgForm, NbiotGroupForm } from '../Forms/NbiotForm'
import { ApplicationForm, TenantForm } from '../Forms/ChirpStackForm';
import AddressTable from './SettingDataAddress';

function SettingDataContent({ selectedMenu, onMenuChange }) {
    const { user, setUser } = useContext(UserContext);

    const forms = [
        { id: "report", name: "Отчет по поверкам", component: <VerifyForm /> },
        { id: "users", name: "Создание Пользователей", component: <UsersTable onMenuChange={onMenuChange} /> },
        { id: "address", name: "Адреса", component: <AddressTable onMenuChange={onMenuChange} /> },
        { id: "createUser", name: "Создание Пользователей", component: <CreateUserForm /> },
        { id: "createUserChirpstack", name: "Создание Пользователей Chirpstack", component: <UserForm /> },
        { id: "createTenant", name: "Создание Организаций", component: <TenantForm /> },
        { id: "createProfile", name: "Создание Профиля устройств", component: <ProfileForm /> },
        { id: "createGateway", name: "Создание Шлюзов", component: <GatewayForm /> },
        { id: "createApplication", name: "Создание Групп устройств(Водоканалы, Товарищества)", component: <ApplicationForm /> },
        { id: "createAddress", name: "Создание Адресов", component: <AddressFormNbiot /> },
        { id: "createNbiotOrg", name: "Создание Организаций", component: <NbiotOrgForm /> },
        { id: "createNbiotGroup", name: "Создание Групп", component: <NbiotGroupForm /> }
    ];

    const selectedForm = forms.find(form => form.id === selectedMenu);

    return (
        <>
            {selectedMenu !== null && selectedForm && selectedForm.component}
        </>
    )
}

export default SettingDataContent;