import React, { useState } from 'react';
import s from "./SettingsMenu.module.css"
import { useNavigate } from 'react-router-dom';
import useIsMobile from '../../store/mobileHook';
import { List, Collapse, ListItemButton, ListItemText } from '@mui/material';

export default function SettingMenu({ onMenuChange }) {
    const isMobile = useIsMobile();
    const [activeItem, setActiveItem] = useState('');
    const [dropdowns, setDropdowns] = useState({});
    const navigate = useNavigate();
    const handleBackClick = () => { navigate('/devices'); };

    const toggleDropdown = (id) => {
        setDropdowns(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return (
        <div className={s.sideBarFrame}>
            <div className={s.forwardback} onClick={handleBackClick}>
                <div className={s.backIcon}>
                    <img src={'/forwardback.svg'}></img>
                </div>
                <div className={s.forwardbackText}>Назад</div>
            </div>
            <div className={s.sideBarBox}>
                <div className={s.sideBarGroup}>
                    <MenuItem
                        text="Адреса"
                        isActive={activeItem === 'item1'}
                        onClick={() => {
                            onMenuChange('address');
                            setActiveItem('item1')
                        }}
                    >
                    </MenuItem>
                    <MenuItem
                        text="Настройки LoRa"
                        isActive={activeItem === 'item2'}
                        onClick={() => {
                            toggleDropdown('lora');
                            setActiveItem('item2');
                            onMenuChange('createTenant');
                        }}
                    >
                        <Collapse in={dropdowns.lora} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 3 }} key={'createTenant'} onClick={() => {onMenuChange('createTenant'); setActiveItem('item2');}}>
                                    <ListItemText primary="Организаций" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 3 }} key={'createApplication'} onClick={() => {onMenuChange('createApplication'); setActiveItem('item2');}}>
                                    <ListItemText primary="Группы" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 3 }} key={'createProfile'} onClick={() => {onMenuChange('createProfile'); setActiveItem('item2');}}>
                                    <ListItemText primary="Профиль устройства" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 3 }} key={'createGateway'} onClick={() => {onMenuChange('createGateway'); setActiveItem('item2');}}>
                                    <ListItemText primary="Шлюзы" />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </MenuItem>
                    <MenuItem
                        text="Найтройки NBIoT"
                        isActive={activeItem === 'item3'}
                        onClick={() => {
                            toggleDropdown('nbiot');
                            setActiveItem('item3');
                            onMenuChange('createNbiotOrg');
                        }}
                    >
                        <Collapse in={dropdowns.nbiot} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{ pl: 3 }} key={'createNbiotOrg'} onClick={() => {onMenuChange('createNbiotOrg'); setActiveItem('item3');}}>
                                    <ListItemText primary="Организаций" />
                                </ListItemButton>
                                <ListItemButton sx={{ pl: 3 }} key={'createNbiotGroup'} onClick={() => {onMenuChange('createNbiotGroup'); setActiveItem('item3');}}>
                                    <ListItemText primary="Группы" />
                                </ListItemButton>
                            </List>
                        </Collapse>
                    </MenuItem>
                    <MenuItem
                        text='Пользователи'
                        isActive={activeItem === 'item4'}
                        onClick={() => {
                            onMenuChange('users');
                            setActiveItem('item4');
                        }}
                    >
                    </MenuItem>
                </div>
            </div>
        </div>
    )
}

function MenuItem({ text, isActive, onClick, children }) {
    return (
        <>
            <div
                className={`${s.menuItem} ${isActive ? s.active : ''}`}
                onClick={onClick}
            >
                <div className={s.articleText}>{text}</div>
            </div>
            {children}
        </>
    );
}