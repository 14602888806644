import * as React from 'react';
import s from "./UserForm.module.css"
import { useFormik } from 'formik';
import { UserContext } from "../../App";
import { TextField, Select, FormControl, InputLabel, MenuItem, Button, Typography, Autocomplete } from '@mui/material';

export const EditForm = ({ email, onSuccess }) => {
    const { user, setUser } = React.useContext(UserContext);
    const [devices, setDevice] = React.useState([]);
    

    const [submitStatus, setSubmitStatus] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState(null);

    React.useEffect(() => {
        async function fetchDevice() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/device/device-list`);
                user.cs_group.forEach(csGroup => {
                    url.searchParams.append('cs_group_id', csGroup._id);
                });
                user.nbiot_group.forEach(nbiotGroup => {
                    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
                });

                let response = await fetch(url);
                const data = await response.json();

                const formatedData = data.map(item => {
                    let dev_id = item._id
                    let dev_number = item.device[0].dev_number
                    let address = `${item.address[0].country} ${item.address[0].region} ${item.address[0].city} ${item.address[0].building} ${item.address_number}`
                    return {
                        dev_id, dev_number, address
                    }
                })

                setDevice(formatedData);
                

            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        }
        fetchDevice();
    }, []);

    const formik = useFormik({
        initialValues: {
            email: "",
            fullname: "",
            phonenumber: "",
            role: "",
            cs_org: [],
            cs_group: [],
            nbiot_org: [],
            nbiot_group: [],
            device: []
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/users/edit`);
                url.searchParams.append('email', email);
                let response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                });
                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }
                const data = await response.json();
                setSubmitStatus('success');
                setSubmitting(false);
                if (onSuccess) {
                    onSuccess();
                }

            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        },
        enableReinitialize: true,
    })

    React.useEffect(() => {
        const fetchData = async () => {
            const url = new URL(`${process.env.REACT_APP_API_URL}/users/edit`);
            url.searchParams.append('email', email);
            let response = await fetch(url);
            let data = await response.json();
            if (response.ok) {
                formik.setValues({
                    email: data.email,
                    fullname: data.fullname,
                    phonenumber: data.phonenumber,
                    role: data.role,
                    cs_org: data.cs_org.map(item => item._id) || [],
                    cs_group: data.cs_group.map(item => item._id) || [],
                    nbiot_org: data.nbiot_org.map(item => item._id) || [],
                    nbiot_group: data.nbiot_group.map(item => item._id) || [],
                    device: data.device || []
                });
            } else {
                console.error('Failed to fetch', data);
            }
        };

        fetchData();
    }, []);

    const ROLES = [
        { value: 'USER', label: 'Обычный пользователь' },
        { value: 'USER_ORG', label: 'Пользователь организации' },
        { value: 'SETTING_ORG', label: 'Наладчик' },
        { value: 'ADMIN_VODOKANAL', label: 'Администратор водоканала' },
        { value: 'ADMIN_ORG', label: 'Администратор организации' },
        { value: 'ADMIN_SERVER', label: 'Администратор сервера' },
        { value: 'ADMIN_CHIRPSTACK', label: 'Администратор Chirpstack' }
    ];

    let availableRoles = [];
    switch (user.role) {
        case 'ADMIN_CHIRPSTACK':
            availableRoles = ROLES;
            break;
        case 'ADMIN_SERVER':
            availableRoles = ROLES.filter(role => role.value !== 'ADMIN_CHIRPSTACK');
            break;
        case 'ADMIN_ORG':
            availableRoles = ROLES.filter(role => !['ADMIN_CHIRPSTACK', 'ADMIN_SERVER'].includes(role.value));
            break;
        case 'ADMIN_VODOKANAL':
            availableRoles = ROLES.filter(role => !['ADMIN_CHIRPSTACK', 'ADMIN_SERVER', 'ADMIN_ORG'].includes(role.value));
            break;
        case 'USER_ORG':
            availableRoles = ROLES.filter(role => !['ADMIN_CHIRPSTACK', 'ADMIN_SERVER', 'ADMIN_ORG', 'ADMIN_VODOKANAL', 'SETTING_ORG'].includes(role.value));
            break;  
        default:
            availableRoles = [];
    }

    const handleFormClick = (event) => {
        event.stopPropagation();
    };

    return (
        <form onSubmit={formik.handleSubmit} onClick={handleFormClick}>
            <Typography variant="h4">
                Редактировать
            </Typography>

            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{s.errorMessage}</div>}
            <TextField
                fullWidth
                margin="normal"
                id="email"
                name="email"
                label="Название"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
            />
            <TextField
                fullWidth
                margin="normal"
                id="fullname"
                name="fullname"
                label="Название"
                value={formik.values.fullname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.fullname && Boolean(formik.errors.fullname)}
            />
            <TextField
                fullWidth
                margin="normal"
                id="phonenumber"
                name="phonenumber"
                label="Название"
                value={formik.values.phonenumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
            />
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Роль пользователя</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="role"
                    name="role"
                    label="Роль пользователя"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                >
                    {availableRoles.map(role => (
                        <MenuItem key={role.value} value={role.value}>{role.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {formik.values.role === 'USER' && (
                <Autocomplete
                    sx={{marginTop: '16px'}}
                    multiple
                    id="tags-outlined"
                    options={devices}
                    getOptionLabel={(option) => `${option?.dev_number || ''} - ${option?.address || ''}`}
                    filterSelectedOptions
                    value={formik.values.device.map(id => devices.find(d => d.dev_id === id)) || []}
                    onChange={(event, value) => {
                        formik.setFieldValue('device', value.map(item => item.dev_id));
                      }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Устройства"
                            placeholder="Выберите устройства"
                        />
                    )}
                />
            )}
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Организация Lora</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="cs_org"
                    name="cs_org"
                    multiple
                    label="Организация Lora"
                    value={formik.values.cs_org}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cs_org && Boolean(formik.errors.cs_org)}
                >
                    {user.cs_org.map(org => (
                        <MenuItem value={org._id} key={org._id}>{org.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств Lora</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="cs_group"
                    name="cs_group"
                    multiple
                    label="Группа устройств Lora"
                    value={formik.values.cs_group}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cs_group && Boolean(formik.errors.cs_group)}
                >
                    {user.cs_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Организация NBIoT</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="nbiot_org"
                    name="nbiot_org"
                    multiple
                    label="Организация NBIoT"
                    value={formik.values.nbiot_org}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nbiot_org && Boolean(formik.errors.nbiot_org)}
                >
                    {user.nbiot_org.map(org => (
                        <MenuItem value={org._id} key={org._id}>{org.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств NBIoT</InputLabel>
                <Select
                    fullWidth
                    multiple
                    margin="normal"
                    id="nbiot_group"
                    name="nbiot_group"
                    label="Группа устройств NBIoT"
                    value={formik.values.nbiot_group}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nbiot_group && Boolean(formik.errors.nbiot_group)}
                >
                    {user.nbiot_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>

    );
};