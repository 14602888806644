import { React, useState, useEffect, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage, FieldArray } from 'formik';
import s from "./SettingData.module.css"
import { exportVerifyData } from '../../store/exportToExcel';
import * as Yup from 'yup';
import { UserContext } from "../../App";
import { useFormik } from 'formik';
import { TextField, Select, FormControl, InputLabel, MenuItem, Button, Typography, Autocomplete } from '@mui/material';
import ModalTemplate from '../ModalForms/ModalForms';

export const useHideMessage = (submitStatus, setSubmitStatus, setErrorMessage) => {
    useEffect(() => {
        if (submitStatus === 'success' || submitStatus === 'error') {
            const timer = setTimeout(() => {
                setSubmitStatus(null);
                setErrorMessage(null);
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [submitStatus, setSubmitStatus, setErrorMessage]);
};



export const UserForm = () => {
    const { user, setUser } = useContext(UserContext);
    const initialValues = {
        password: "",
        isAdmin: true,
        isDeviceAdmin: true,
        isGatewayAdmin: true,
        tenantId: "",
        email: "",
        isActive: true,
        isAdmin: true,
        note: ""
    };

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (values, { resetForm }) => {
        const request = {
            password: values.password,
            tenants: [
                {
                    isAdmin: values.isAdmin,
                    isDeviceAdmin: values.isDeviceAdmin,
                    isGatewayAdmin: values.isGatewayAdmin,
                    tenantId: values.tenantId
                }
            ],
            user: {
                email: values.email,
                isActive: values.isActive,
                isAdmin: values.isAdmin,
                note: values.note
            }
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/chirpstack/create-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

            if (!response.ok) {
                const errorText = await response.text();
                setErrorMessage(errorText);
                setSubmitStatus('error');
                return;
            }

            const data = await response.json(); // Декодируем JSON из ответа
            console.log(data);
            setSubmitStatus('success');
            resetForm();

        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
                <Form className={s.form}>
                    {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
                    {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
                    <div>
                        <label htmlFor="password">Пароль:</label>
                        <div className={s.inputField}><Field type="text" name="password" /></div>
                    </div>
                    <div>
                        <label htmlFor="tenantId">Название организации:</label>
                        <div className={s.inputField}><Field as="select" name="tenant">
                            <option value="">Выберете организацию</option>
                            {user.cs_org.map(org => (
                                <option value={org._id} key={org._id}>
                                    {org.name}
                                </option>
                            ))}
                        </Field>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="email">Е-майл:</label>
                        <div className={s.inputField}><Field type="text" name="email" /></div>
                    </div>
                    <div>
                        <label htmlFor="note">Заметки:</label>
                        <div className={s.inputField}><Field type="text" name="note" /></div>
                    </div>
                    <div className={s.inCheck}>
                        <label htmlFor="isAdmin">Права администрирования организацией:</label>
                        <Field type="checkbox" name="isAdmin" />
                    </div>
                    <div className={s.inCheck}>
                        <label htmlFor="isDeviceAdmin">Права администрирования устройствами организации:</label>
                        <Field type="checkbox" name="isDeviceAdmin" />
                    </div>
                    <div className={s.inCheck}>
                        <label htmlFor="isGatewayAdmin">Права администрирования шлюзами организации:</label>
                        <Field type="checkbox" name="isGatewayAdmin" />
                    </div>
                    <div className={s.inCheck}>
                        <label htmlFor="isActive">Активировать пользователя:</label>
                        <Field type="checkbox" name="isActive" />
                    </div>
                    <div className={s.inCheck}>
                        <label htmlFor="isAdmin">Права администратора(Верхний уровень):</label>
                        <Field type="checkbox" name="isAdmin" />
                    </div>
                    <button className={s.btn} disabled={isSubmitting}>
                        Отправить
                    </button>
                </Form>
            )}
        </Formik>
    );
}


export const ProfileForm = () => {
    const { user, setUser } = useContext(UserContext);


    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            description: "",
            tenantId: "",
            region: "EU868",
            macVersion: "LORAWAN_1_0_2",
            regParamsRevision: "A",
            payloadCodecRuntime: "JS",
            adrAlgorithmId: "Default ADR algorithm (LoRa only)",
            deviceStatusReqInterval: 1,
            uplinkInterval: 3600,
            flushQueueOnActivate: true,
            supportsOtaa: true,
            supportsClassB: false,
            supportsClassC: false,
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/chirpstack/create-device-profile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4">
                Создание профиля устройств
            </Typography>

            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <TextField
                fullWidth
                margin="normal"
                id="name"
                name="name"
                label="Название"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
                fullWidth
                margin="normal"
                id="description"
                name="description"
                label="Описание"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
            />
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Название организации</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="tenantId"
                    name="tenantId"
                    label="Название организации"
                    value={formik.values.tenantId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tenantId && Boolean(formik.errors.tenantId)}
                    helperText={formik.touched.tenantId && formik.errors.tenantId}
                >
                    {user.cs_org.map(org => (
                        <MenuItem value={org.cs_org_id} key={org._id}>{org.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                fullWidth
                margin="normal"
                id="region"
                name="region"
                label="LoRa регион"
                value={formik.values.region}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.region && Boolean(formik.errors.region)}
                helperText={formik.touched.region && formik.errors.region}
            />
            <TextField
                fullWidth
                margin="normal"
                id="macVersion"
                name="macVersion"
                label="Версия LoRa"
                value={formik.values.macVersion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.macVersion && Boolean(formik.errors.macVersion)}
                helperText={formik.touched.macVersion && formik.errors.macVersion}
            />
            <TextField
                fullWidth
                margin="normal"
                id="regParamsRevision"
                name="regParamsRevision"
                label="Тип региональных параметров"
                value={formik.values.regParamsRevision}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.regParamsRevision && Boolean(formik.errors.regParamsRevision)}
                helperText={formik.touched.regParamsRevision && formik.errors.regParamsRevision}
            />
            <TextField
                fullWidth
                margin="normal"
                id="payloadCodecRuntime"
                name="payloadCodecRuntime"
                label="Тип кодека"
                value={formik.values.payloadCodecRuntime}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.payloadCodecRuntime && Boolean(formik.errors.payloadCodecRuntime)}
                helperText={formik.touched.payloadCodecRuntime && formik.errors.payloadCodecRuntime}
            />
            <TextField
                fullWidth
                margin="normal"
                id="adrAlgorithmId"
                name="adrAlgorithmId"
                label="Алгоритм LoRa"
                value={formik.values.adrAlgorithmId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.adrAlgorithmId && Boolean(formik.errors.adrAlgorithmId)}
                helperText={formik.touched.adrAlgorithmId && formik.errors.adrAlgorithmId}
            />
            <TextField
                fullWidth
                margin="normal"
                id="deviceStatusReqInterval"
                name="deviceStatusReqInterval"
                type="number"
                label="Интервал передачи устройства"
                value={formik.values.deviceStatusReqInterval}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.deviceStatusReqInterval && Boolean(formik.errors.deviceStatusReqInterval)}
                helperText={formik.touched.deviceStatusReqInterval && formik.errors.deviceStatusReqInterval}
            />
            <TextField
                fullWidth
                margin="normal"
                id="uplinkInterval"
                name="uplinkInterval"
                type="number"
                label="Интервал восходящей линии"
                value={formik.values.uplinkInterval}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.uplinkInterval && Boolean(formik.errors.uplinkInterval)}
                helperText={formik.touched.uplinkInterval && formik.errors.uplinkInterval}
            />
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Устройства Class-A</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="supportsOtaa"
                    name="supportsOtaa"
                    label="Устройства Class-A"
                    value={formik.values.supportsOtaa}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.supportsOtaa && Boolean(formik.errors.supportsOtaa)}
                    helperText={formik.touched.supportsOtaa && formik.errors.supportsOtaa}
                >
                    <MenuItem value={true}>Да</MenuItem>
                    <MenuItem value={false}>Нет</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Устройства Class-B</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="supportsClassB"
                    name="supportsClassB"
                    label="Устройства Class-B"
                    value={formik.values.supportsClassB}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.supportsClassB && Boolean(formik.errors.supportsClassB)}
                    helperText={formik.touched.supportsClassB && formik.errors.supportsClassB}
                >
                    <MenuItem value={true}>Да</MenuItem>
                    <MenuItem value={false}>Нет</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Устройства Class-C</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="supportsClassC"
                    name="supportsClassC"
                    label="Устройства Class-C"
                    value={formik.values.supportsClassC}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.supportsClassC && Boolean(formik.errors.supportsClassC)}
                    helperText={formik.touched.supportsClassC && formik.errors.supportsClassC}
                >
                    <MenuItem value={true}>Да</MenuItem>
                    <MenuItem value={false}>Нет</MenuItem>
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Очистить очередь перед активацией устройства</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="flushQueueOnActivate"
                    name="flushQueueOnActivate"
                    label="Очистить очередь перед активацией устройства"
                    value={formik.values.flushQueueOnActivate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.flushQueueOnActivate && Boolean(formik.errors.flushQueueOnActivate)}
                    helperText={formik.touched.flushQueueOnActivate && formik.errors.flushQueueOnActivate}
                >
                    <MenuItem value={true}>Да</MenuItem>
                    <MenuItem value={false}>Нет</MenuItem>
                </Select>
            </FormControl>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    )
}


export const GatewayForm = () => {
    const { user, setUser } = useContext(UserContext);


    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const formik = useFormik({
        initialValues: {
            name: "",
            gatewayId: "",
            description: "",
            tenantId: "",
            statsInterval: 30,
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/chirpstack/create-gateway`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                });


                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);


    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4">
                Создание шлюзов
            </Typography>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <TextField
                fullWidth
                margin="normal"
                id="name"
                name="name"
                label="Название"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
                fullWidth
                margin="normal"
                id="description"
                name="description"
                label="Описание"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
            />
            <TextField
                fullWidth
                margin="normal"
                id="gatewayId"
                name="gatewayId"
                label="Идентификатор шлюза (EUI64)"
                value={formik.values.gatewayId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.gatewayId && Boolean(formik.errors.gatewayId)}
                helperText={formik.touched.gatewayId && formik.errors.gatewayId}
            />
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Название организации</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="tenantId"
                    name="tenantId"
                    label="Название организации"
                    value={formik.values.tenantId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.tenantId && Boolean(formik.errors.tenantId)}
                    helperText={formik.touched.tenantId && formik.errors.tenantId}
                >
                    {user.cs_org.map(org => (
                        <MenuItem value={org.cs_org_id} key={org._id}>{org.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                fullWidth
                margin="normal"
                id="statsInterval"
                name="statsInterval"
                type="number"
                label="Интервал статистики"
                value={formik.values.statsInterval}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.statsInterval && Boolean(formik.errors.statsInterval)}
                helperText={formik.touched.statsInterval && formik.errors.statsInterval}
            />
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>

    );
}


export const VerifyForm = () => {
    const initialValues = {
        dateValue: ""
    }

    const handleSubmit = (values, { resetForm }) => {
        exportVerifyData(values);
        console.log("Form submitted:", values);
        resetForm();
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
                <Form className={s.form}>
                    <div>
                        <label htmlFor="dateValue">Дата окончания поверки:</label>
                        <div className={s.inputField}><Field type="date" name="dateValue" /></div>
                    </div>
                    <button className={s.btn} disabled={isSubmitting}>
                        Сформировать отчет
                    </button>
                </Form>
            )}

        </Formik>

    )
}


export const CreateUserForm = () => {
    const { user, setUser } = useContext(UserContext);
    const [devices, setDevice] = useState([]);

    useEffect(() => {
        async function fetchDevice() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/device/device-list`);
                user.cs_group.forEach(csGroup => {
                    url.searchParams.append('cs_group_id', csGroup._id);
                });
                user.nbiot_group.forEach(nbiotGroup => {
                    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
                });
                url.searchParams.append('limit', 0);
                url.searchParams.append('offset', 0);


                let response = await fetch(url);
                const data = await response.json();

                const formatedData = data.map(item => {
                    let dev_id = item._id
                    let dev_number = item.device[0].dev_number
                    let address = `${item.address[0].country} ${item.address[0].region} ${item.address[0].city} ${item.address[0].building} ${item.address_number}`
                    return {
                        dev_id, dev_number, address
                    }
                })

                setDevice(formatedData);

            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        }
        fetchDevice();
    }, []);

    const formik = useFormik({
        initialValues: {
            password: "",
            email: "",
            fullname: "",
            phonenumber: "",
            role: "",
            cs_org: [],
            cs_group: [],
            nbiot_org: [],
            nbiot_group: [],
            device_number: []
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const body = {
                ...values
            };
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/create-user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                setSubmitStatus('success');
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    const ROLES = [
        { value: 'USER', label: 'Обычный пользователь' },
        { value: 'USER_ORG', label: 'Пользователь организации' },
        { value: 'SETTING_ORG', label: 'Наладчик' },
        { value: 'ADMIN_VODOKANAL', label: 'Администратор водоканала' },
        { value: 'ADMIN_ORG', label: 'Администратор организации' },
        { value: 'ADMIN_SERVER', label: 'Администратор сервера' },
        { value: 'ADMIN_CHIRPSTACK', label: 'Администратор Chirpstack' }
    ];

    let availableRoles = [];
    switch (user.role) {
        case 'ADMIN_CHIRPSTACK':
            availableRoles = ROLES;
            break;
        case 'ADMIN_SERVER':
            availableRoles = ROLES.filter(role => role.value !== 'ADMIN_CHIRPSTACK');
            break;
        case 'ADMIN_ORG':
            availableRoles = ROLES.filter(role => !['ADMIN_CHIRPSTACK', 'ADMIN_SERVER'].includes(role.value));
            break;
        case 'ADMIN_VODOKANAL':
            availableRoles = ROLES.filter(role => !['ADMIN_CHIRPSTACK', 'ADMIN_SERVER', 'ADMIN_ORG'].includes(role.value));
            break;
        case 'USER_ORG':
            availableRoles = ROLES.filter(role => !['ADMIN_CHIRPSTACK', 'ADMIN_SERVER', 'ADMIN_ORG', 'ADMIN_VODOKANAL', 'SETTING_ORG'].includes(role.value));
            break;
        default:
            availableRoles = [];
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().matches(/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/, ('Неправильный е-майл'))
            .required(('Поле обязательно')),
        password: Yup.string().required(('Поле обязательно'))
            .min(8, ('Минимальная длина 8 символов')),
    });

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h4">
                Создание пользователя
            </Typography>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}

            <TextField
                fullWidth
                margin="normal"
                id="email"
                name="email"
                label="Электронная почта"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
                fullWidth
                margin="normal"
                id="password"
                name="password"
                label="Пароль"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
                fullWidth
                margin="normal"
                id="fullname"
                name="fullname"
                label="Название организации или ФИО"
                value={formik.values.fullname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.fullname && Boolean(formik.errors.fullname)}
                helperText={formik.touched.fullname && formik.errors.fullname}
            />
            <TextField
                fullWidth
                margin="normal"
                id="phonenumber"
                name="phonenumber"
                label="Телефон"
                value={formik.values.phonenumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phonenumber && Boolean(formik.errors.phonenumber)}
                helperText={formik.touched.phonenumber && formik.errors.phonenumber}
            />
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Роль пользователя</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="role"
                    name="role"
                    label="Роль пользователя"
                    value={formik.values.role}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                >
                    {availableRoles.map(role => (
                        <MenuItem key={role.value} value={role.value}>{role.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>

            {formik.values.role === 'USER' && (
                <Autocomplete
                    sx={{ marginTop: '16px' }}
                    multiple
                    id="tags-outlined"
                    options={devices}
                    getOptionLabel={(option) => `${option.dev_number} - ${option.address}`}
                    filterSelectedOptions
                    onChange={(event, value) => {
                        formik.setFieldValue('device', value.map(item => item.dev_id));
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Устройства"
                            placeholder="Выберите устройства"
                        />
                    )}
                />
            )}

            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Организация Lora</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="cs_org"
                    name="cs_org"
                    multiple
                    label="Организация Lora"
                    value={formik.values.cs_org}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cs_org && Boolean(formik.errors.cs_org)}
                    helperText={formik.touched.cs_org && formik.errors.cs_org}
                >
                    {user.cs_org.map(org => (
                        <MenuItem value={org._id} key={org._id}>{org.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств Lora</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="cs_group"
                    name="cs_group"
                    multiple
                    label="Группа устройств Lora"
                    value={formik.values.cs_group}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cs_group && Boolean(formik.errors.cs_group)}
                    helperText={formik.touched.cs_group && formik.errors.cs_group}
                >
                    {user.cs_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Организация NBIoT</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="nbiot_org"
                    name="nbiot_org"
                    multiple
                    label="Организация NBIoT"
                    value={formik.values.nbiot_org}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nbiot_org && Boolean(formik.errors.nbiot_org)}
                    helperText={formik.touched.nbiot_org && formik.errors.nbiot_org}
                >
                    {user.nbiot_org.map(org => (
                        <MenuItem value={org._id} key={org._id}>{org.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств NBIoT</InputLabel>
                <Select
                    fullWidth
                    multiple
                    margin="normal"
                    id="nbiot_group"
                    name="nbiot_group"
                    label="Группа устройств NBIoT"
                    value={formik.values.nbiot_group}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.nbiot_group && Boolean(formik.errors.nbiot_group)}
                    helperText={formik.touched.nbiot_group && formik.errors.nbiot_group}
                >
                    {user.nbiot_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}

