
export function utilsDatetime() {
  const timestamp = Date.now();
  const date = new Date(timestamp);
  const humanReadableDate = date.toLocaleString();
  return humanReadableDate
}

export async function exportVerifyData(date) {
  const authToken = localStorage.getItem('token');


  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/verifydevice?date=${date}`, {
      method: 'POST',
    });
    if (response.ok) {
      // Получение URL для скачивания файла
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_о_поверке_${utilsDatetime()}.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      // Обработка ошибок
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    // Обработка ошибок сети или других проблем
    console.error('Error:', error);
  }
}

export async function exportToExel({ dataToExport }) {
  const authToken = localStorage.getItem('token');

  const { utilsData, filterType,
    fullAddress, searchQuery, order, readingdate, user } = dataToExport;

  try {
    let [selectedOption, toDate, fromDate] = utilsData;
    const formatDateToRFC3339 = (date) => date.toISOString();
    const getDateBoundary = (date, option) => {
      const newDate = new Date(date);
      if (option === 'startOfMonth') {
        return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth() + 1, 0, 23, 59, 59, 999));
      } else if (option === 'startOfYear') {
        const lastMonthOfYear = 11;
        newDate.setMonth(lastMonthOfYear);
        newDate.setDate(31);
        return new Date(Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate(), 23, 59, 59, 999));
      }
      return new Date(`${date}T23:59:59.999Z`);
    };

    const fetchToDate = formatDateToRFC3339(getDateBoundary(toDate, selectedOption));
    const fetchFromDate = formatDateToRFC3339(new Date(fromDate));
    const url = new URL(`${process.env.REACT_APP_API_URL}/reports/excel`);
    user.cs_group.forEach(csGroup => {
      url.searchParams.append('cs_group_id', csGroup._id);
    });
    user.nbiot_group.forEach(nbiotGroup => {
      url.searchParams.append('nbiot_group_id', nbiotGroup._id);
    });

    url.searchParams.append('limit', 0);
    url.searchParams.append('offset', 0);
    url.searchParams.append('address', fullAddress)
    url.searchParams.append('search', searchQuery)
    url.searchParams.append('order', order)
    url.searchParams.append('detail', selectedOption);
    url.searchParams.append('toDate', fetchToDate);
    url.searchParams.append('fromDate', fetchFromDate);
    url.searchParams.append('filterType', filterType);
    url.searchParams.append('username', user.username);
    url.searchParams.append('readingdate', readingdate);

    const response = await fetch(url);

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_за_период_${utilsDatetime()}.xlsx`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    console.error('Error:', error);
  }
}


export async function exportDeviceToExel({ dataToExport }) {

  const data = dataToExport;
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/device/exceldevice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
    });

    if (response.ok) {
      // Получение URL для скачивания файла
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_потребления_устройства_${utilsDatetime()}.xlsx`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      // Обработка ошибок
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    // Обработка ошибок сети или других проблем
    console.error('Error:', error);
  }

}



export async function exportTemplatesXLSX({ dataToExport }) {

  try {
    const url = new URL(`${process.env.REACT_APP_API_URL}/reports/device-thermal`);
    url.searchParams.append('_id', dataToExport.deviceUuid);
    url.searchParams.append('dev_number', dataToExport.deviceNumber);
    url.searchParams.append('template', dataToExport.template);
    url.searchParams.append('org', dataToExport.org);
    url.searchParams.append('fromDate', dataToExport.startDate);
    url.searchParams.append('toDate', dataToExport.endDate);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (response.ok) {
      // Получение URL для скачивания файла
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_${utilsDatetime()}.xlsx`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      // Обработка ошибок
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    // Обработка ошибок сети или других проблем
    console.error('Error:', error);
  }

}

export async function exportDevicesToExcel({ user }) {
  try {
    const url = new URL(`${process.env.REACT_APP_API_URL}/device/export`);
    user.cs_group.forEach(csGroup => {
      url.searchParams.append('cs_group_id', csGroup._id);
    });
    user.nbiot_group.forEach(nbiotGroup => {
      url.searchParams.append('nbiot_group_id', nbiotGroup._id);
    });

    const response = await fetch(url);
    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Экспорт_устройств_${utilsDatetime()}.xlsx`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Error:', response.statusText);
    }

  } catch (error) {
    console.error('Error fetching logs:', error);
  }
}

export async function exportErip(dataToExport) {


  const data = dataToExport;
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/reports/eripcsv`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Отчет_для_ЕРИП_${utilsDatetime()}.txt`;

      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } else {
      console.error('Error:', response.statusText);
    }
  } catch (error) {
    console.error('Error:', error);
  }

}    