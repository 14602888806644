import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../../App";
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../../src/i18n';
import { ruRU } from '@mui/x-data-grid/locales'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import s from "./SettingData.module.css"
import { AlertBar } from '../Utils/Utils';
import { AddressEditForm, AddressForm, AddressFormChirpStack, AddressFormNbiot } from '../Forms/AddressForm';
import ModalTemplate from '../ModalForms/ModalForms';


function AddressTable({ onMenuChange }) {

    const [open, setOpen] = useState(false);
    const [alertInfo, setAlertInfo] = useState({ severity: 'info', message: '' });
    const { user, setUser } = useContext(UserContext);
    const [editingAddress, setEditingAddress] = useState({});
    const [data, setData] = useState([])
    const { t } = useTranslation();
    const [openAddress, setOpenAddress] = useState(false);
    const handleOpenAddress = () => setOpenAddress(true);
    const handleCloseAddress = () => setOpenAddress(false);

    const [openEditAddress, setOpenEditAddress] = useState(false);
    const handleOpenEditAddress = () => setOpenEditAddress(true);
    const handleCloseEditAddress = () => setOpenEditAddress(false);

    const theme = createTheme(
        ruRU
    );

    useEffect(() => {
        async function fetchData() {
            if (user && user.email) {
                try {
                    const url = new URL(`${process.env.REACT_APP_API_URL}/address/list`);
                    user.cs_group.forEach(csGroup => {
                        url.searchParams.append('cs_group_id', csGroup._id);
                    });
                    user.nbiot_group.forEach(nbiotGroup => {
                        url.searchParams.append('nbiot_group_id', nbiotGroup._id);
                    });

                    let response = await fetch(url);
                    const data = await response.json();
                    setData(data)
                } catch (error) {
                    console.error('Error fetching device:', error);
                }
            }
        }
        fetchData();
    }, [user, alertInfo]);

    const deleteAddress = async (id, countDevice) => {
        try {
            if (countDevice > 0) {
                setAlertInfo({ severity: 'error', message: t('deleteFalse') });
                setOpen(true);
                return;
            }
            const url = new URL(`${process.env.REACT_APP_API_URL}/address/delete`);
            url.searchParams.append('_id', id);
            let response = await fetch(url, {
                method: 'POST'
            });
            if (response.ok) {
                setAlertInfo({ severity: 'success', message: t('deleteTrue') });
                setOpen(true);
                await fetchData();
            } else {
                throw new Error('Failed to delete the address');
            }
        } catch (error) {
            console.error('Error deleting address:', error);
        }
    }

    const editAddress = (_id, _group) => {
        setEditingAddress({ id: _id, group: _group });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSuccess = (data) => {
        handleCloseEditAddress();
        setAlertInfo(data);
        setOpen(true);
    };

    const rows = data.map(item => ({
        id: item._id,
        countDevice: item.device_count,
        groupName: item.cs_group[0]?.name ? "LoRa" : item.nbiot_group[0]?.name ? "NBIoT" : null,
        country: item.country,
        region: item.region,
        city: item.city,
        street: item.street,
        building: item.building,
        latlng: `${item.lat},${item.lng}`
    }))

    const columns = [
        { field: 'countDevice', headerName: t('countDevice'), width: 100 },
        { field: 'groupName', headerName: t('groupName'), width: 130 },
        { field: 'country', headerName: t('country'), width: 130 },
        { field: 'region', headerName: t('region'), width: 150 },
        { field: 'city', headerName: t('city'), width: 160 },
        { field: 'street', headerName: t('street'), width: 160 },
        { field: 'building', headerName: t('building'), width: 100 },
        { field: 'latlng', headerName: t('latlng'), width: 140 },
        {
            field: 'action',
            headerName: t('action'),
            width: 100,
            sortable: false,
            renderCell: (params) => (

                <div className={s.action}>
                    <div className={s.actEditButton} onClick={(event) => {
                        event.stopPropagation();
                        editAddress(params.row.id, params.row.groupName);
                        handleOpenEditAddress();
                    }}>
                        <EditIcon />
                    </div>
                    <div className={s.actDelButton} onClick={() => { deleteAddress(params.row.id, params.row.countDevice) }}>
                        <DeleteIcon />
                    </div>
                </div>
            ),
        },
    ];



    return (
        <ThemeProvider theme={theme}>
            <AlertBar open={open} handleClose={handleClose} message={alertInfo.message} severity={alertInfo.severity} >

            </AlertBar>

            <ModalTemplate
                open={openAddress}
                onClose={handleCloseAddress}
            >
                <AddressForm onSuccess={handleCloseAddress} />
            </ModalTemplate>

            <Box sx={{ width: '100%' }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{
                        marginBottom: 1
                    }}
                    onClick={handleOpenAddress}
                >
                    {t('createAddress')}
                </Button>
                <Box sx={{ width: '100%', overflowX: 'auto' }}>
                    <DataGrid
                        columns={columns}
                        rows={rows}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        autoHeight
                        pageSizeOptions={[10, 20, 50, 100]}
                        sx={{
                            "& p": {
                                marginTop: "auto",
                                marginBottom: "1rem",
                            },
                        }}
                    />
                </Box>
                <ModalTemplate
                    open={openEditAddress}
                    onClose={handleCloseEditAddress}
                >
                    
                    <AddressEditForm onSuccess={handleSuccess} changeData={editingAddress}></AddressEditForm>
  
                </ModalTemplate>
            </Box>
        </ThemeProvider>
    )



}

export default AddressTable;

