import React, { useState, useEffect } from 'react';
import s from "./ReportUtils.module.css"

function ReportChoiceData({ sendDataToParent }) {
    const [selectedOption, setSelectedOption] = useState('current');
    const [fromDate, setFromDate] = useState(() => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1); 
        console.log(firstDayOfMonth);
        console.log(firstDayOfMonth.toLocaleDateString('en-CA'));       
        return firstDayOfMonth.toLocaleDateString('en-CA');
    });
    const [toDate, setToDate] = useState(() => {
        const currentDate = new Date();
        const endOfDay = new Date(currentDate);
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay.toLocaleDateString('en-CA');
    });

    const getStartCurrent = () => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        console.log(firstDayOfMonth);
        console.log(firstDayOfMonth.toLocaleDateString('en-CA'));
        return firstDayOfMonth.toLocaleDateString('en-CA');
    };
    const getEndOfCurrent = () => {
        const currentDate = new Date();
        const endOfDay = new Date(currentDate);
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay.toLocaleDateString('en-CA');
    };

    const getStartDay = () => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        return currentDate.toLocaleDateString('en-CA');
    };
    const getEndOfDay = () => {
        const currentDate = new Date();
        const endOfDay = new Date(currentDate);
        endOfDay.setHours(23, 59, 59, 999);
        return endOfDay.toLocaleDateString('en-CA');
    };

    const getStartOfMonth = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    };

    const getEndOfMonth = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${month}`;
    };

    const getStartOfYear = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        return `${year}`;
    };

    const getEndOfYear = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        return `${year}`;
    };

    const handleSelection = (event) => {
        setSelectedOption(event.target.value);
        if (event.target.value === 'startOfMonth') {
            const startOfMonth = getStartOfMonth();
            const endOfMonth = getEndOfMonth();
            setFromDate(startOfMonth);
            setToDate(endOfMonth);
        } else if (event.target.value === 'startOfYear') {
            const startOfYear = getStartOfYear();
            const endOfYear = getEndOfYear();
            setFromDate(startOfYear);
            setToDate(endOfYear);
        } else if (event.target.value === 'startOfDay') {
            const startOfDay = getStartDay();
            const endOfDay = getEndOfDay();
            setFromDate(startOfDay);
            setToDate(endOfDay);
        } else {
            const start = getStartCurrent();
            const end = getEndOfCurrent();
            setFromDate(start);
            setToDate(end);
        }
    };

    useEffect(() => {
        const initialData = [selectedOption, toDate, fromDate];
        sendDataToParent(initialData);
    }, [selectedOption, toDate, fromDate]);

    const options = [
        { value: 'current', text: 'Текущие' },
        { value: 'startOfDay', text: 'Сутки' },
        { value: 'startOfMonth', text: 'Месяц' },
        { value: 'startOfYear', text: 'Год' }
    ];    

    let fromInputType = 'date';
    let toInputType = 'date';
    let styledisplay = 'inline'

    if (selectedOption === 'startOfMonth') {
        fromInputType = toInputType = 'month';
    } else if (selectedOption === 'startOfYear') {
        fromInputType = toInputType = 'number';
    } else if (selectedOption === 'startOfDay') {
        fromInputType = toInputType = 'date';
    } else if (selectedOption === 'current') {
        fromInputType = toInputType = 'date';
        styledisplay = 'None'
    }    

    return (
        <div className={s.periodFrame}>
            <div className={s.period}>Период: </div>
            <div className={s.dateForm}>
                <input
                    className={s.startDate}
                    type={fromInputType}
                    id="from-date"
                    value={fromDate}
                    style={{ display: styledisplay }}

                    onChange={(e) => setFromDate(e.target.value)}
                />
                <div className={s.decil} style={{ display: styledisplay }}></div>
                <input
                    className={s.endDate}
                    type={toInputType}
                    id="to-date"
                    value={toDate}
                    style={{ display: styledisplay }}
                    onChange={(e) => setToDate(e.target.value)}
                />
            </div>
            <select className={s.tableSelect} onChange={handleSelection}>
                {options.map((option, index) => (
                    <option value={option.value} key={index}>
                        {option.text}
                    </option>
                ))}
            </select>
        </div>
    );
}



export { ReportChoiceData };
