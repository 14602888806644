export const EditIcon = ({ fill, opacity }) => (
    <svg width="20" height="20" viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M2 16H3.425L13.2 6.225L11.775 4.8L2 14.575V16ZM0 18V13.75L13.2 0.575C13.4 0.391667 13.6208 0.25 13.8625 0.15C14.1042 0.05 14.3583 0 14.625 0C14.8917 0 15.15 0.05 15.4 0.15C15.65 0.25 15.8667 0.4 16.05 0.6L17.425 2C17.625 2.18333 17.7708 2.4 17.8625 2.65C17.9542 2.9 18 3.15 18 3.4C18 3.66667 17.9542 3.92083 17.8625 4.1625C17.7708 4.40417 17.625 4.625 17.425 4.825L4.25 18H0ZM12.475 5.525L11.775 4.8L13.2 6.225L12.475 5.525Z" fill={fill} fill-opacity={opacity} />
    </svg>
)


export const DeleteTableIcon = ({ fill }) => (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 24 24" fill={fill}>

        <path class="st0" d="M171.77,484.5h168.46c38.84,0,70.43-31.6,70.43-70.43V208.76c23.42-8.42,40.21-30.85,40.21-57.13v-1.27
			c0-33.47-27.23-60.7-60.7-60.7h-64.86C321.52,54.76,291.89,27.5,256,27.5s-65.52,27.26-69.31,62.16h-64.86
			c-33.47,0-60.7,27.23-60.7,60.7v1.27c0,26.28,16.79,48.71,40.21,57.13v205.31C101.34,452.9,132.93,484.5,171.77,484.5z
			 M378.66,414.07c0,21.19-17.24,38.43-38.43,38.43H171.77c-21.19,0-38.43-17.24-38.43-38.43V212.32h245.33V414.07z M256,59.5
			c18.21,0,33.45,12.97,36.96,30.16h-73.93C222.55,72.47,237.79,59.5,256,59.5z M93.13,150.36c0-15.83,12.87-28.7,28.7-28.7h80.45
			h107.45h80.45c15.82,0,28.7,12.87,28.7,28.7v1.27c0,15.83-12.87,28.7-28.7,28.7H121.83c-15.82,0-28.7-12.87-28.7-28.7V150.36z" fill={fill} />
        <path class="st0" d="M214.6,407.05c8.84,0,16-7.16,16-16V273.78c0-8.84-7.16-16-16-16s-16,7.16-16,16v117.27
			C198.6,399.88,205.76,407.05,214.6,407.05z" fill={fill} />
        <path class="st0" d="M297.4,407.05c8.84,0,16-7.16,16-16V273.78c0-8.84-7.16-16-16-16s-16,7.16-16,16v117.27
			C281.4,399.88,288.57,407.05,297.4,407.05z" fill={fill} />

    </svg>
)

export const EditTableIcon = ({ fill, opacity }) => (

    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
        viewBox="0 0 24 24" fill={fill}>

        <path class="st0" d="M43.5,482.5h108.54c0.54,0,1.07-0.03,1.6-0.08c0.39-0.04,0.78-0.11,1.17-0.17c0.13-0.02,0.26-0.03,0.39-0.06
			c0.46-0.09,0.91-0.21,1.36-0.34c0.05-0.01,0.1-0.02,0.15-0.04c0.45-0.14,0.9-0.3,1.33-0.48c0.04-0.02,0.09-0.03,0.14-0.05
			c0.42-0.17,0.82-0.37,1.21-0.57c0.07-0.03,0.14-0.06,0.2-0.1c0.37-0.2,0.72-0.41,1.07-0.64c0.09-0.06,0.19-0.11,0.28-0.17
			c0.33-0.22,0.65-0.47,0.96-0.71c0.1-0.08,0.2-0.14,0.29-0.22c0.41-0.33,0.8-0.69,1.17-1.06l246.98-246.98
			c0.02-0.02,0.04-0.04,0.07-0.06s0.04-0.04,0.06-0.07l58.92-58.92v0c5.81-5.81,9.01-13.53,9.01-21.75c0-8.21-3.2-15.94-9.01-21.74
			l-83.7-83.69c-11.99-11.99-31.5-11.99-43.49,0l-58.96,58.96c-0.01,0.01-0.02,0.01-0.02,0.02s-0.01,0.02-0.02,0.02l-251.01,251
			c-0.37,0.37-0.72,0.76-1.05,1.16c-0.09,0.1-0.16,0.22-0.24,0.32c-0.24,0.31-0.48,0.61-0.69,0.94c-0.07,0.1-0.12,0.2-0.18,0.3
			c-0.22,0.34-0.43,0.69-0.63,1.05c-0.04,0.07-0.07,0.15-0.1,0.22c-0.2,0.39-0.4,0.79-0.57,1.2c-0.02,0.05-0.04,0.1-0.06,0.15
			c-0.17,0.43-0.34,0.87-0.47,1.32c-0.02,0.05-0.03,0.11-0.04,0.16c-0.13,0.44-0.25,0.89-0.34,1.35c-0.03,0.13-0.04,0.27-0.06,0.4
			c-0.07,0.38-0.13,0.76-0.17,1.15c-0.05,0.53-0.08,1.06-0.08,1.6V466.5C27.5,475.34,34.66,482.5,43.5,482.5z M59.5,450.5v-77.95
			l235.03-235.02l81.93,81.93L145.41,450.5H59.5z M363.94,68.12l81.93,81.92l-46.79,46.79l-81.93-81.93L363.94,68.12z" fill={fill} />

    </svg>
)