import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../../App"; 1
import s from "./LogsReport.module.css"




const Table = ({ logs }) => {

    const headers = ['Тип', 'Период', 'Дата создания', 'Примечание', 'Файл']

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemPerPage] = useState(10);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = logs.slice(indexOfFirstItem, indexOfLastItem);

    const renderItems = currentItems.map(item => (
        <tr key={item.id}>
            <td className={s.tableListText}>
                {item.type}
            </td>
            <td className={s.tableListText}>
                {item.period}
            </td>
            <td className={s.tableListText}>
                {item.create_date}
            </td>
            <td className={s.tableListText}>
                {item.info}
            </td>
            <td>
                {item.message && (
                    <a href={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${item.message}`} target="_blank" rel="noopener noreferrer">Скачать файл</a>

                )}
            </td>
        </tr>
    ))

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(logs.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const totalPages = Math.ceil(logs.length / itemsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleItemsPerPageChange = (event) => {
        setItemPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    return (
        <div className={s.tableContainer}>
            <table className={s.deviceTable}>
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th key={header}>
                                <div className={s.warp}>
                                    <div className={s.frame48Text}>{header}</div>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <>{renderItems}</>
                </tbody>
            </table>
            <div className={s.paginate}>
                <div className={s.quantityDevice}>
                    {logs.length} всего
                </div>
                <div className={s.devicePerPage}>
                    <div className={s.devicePerPageText}>Показать</div>
                    <select className={s.inputPerPage} value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className={s.currentPage}>
                    <div className={s.arrowLeft} onClick={handlePreviousPage}>
                        <img src={'/arrowleft.svg'}></img>
                    </div>
                    <div className={s.page}>{currentPage}</div>
                    <div className={s.arrowRight} onClick={handleNextPage}>
                        <img src={'/arrowright.svg'}></img>
                    </div>
                </div>
            </div>
        </div>
    );

}



const LogsReport = ({ handleBackClick }) => {
    const [logs, setLogs] = useState([]);
    const { user, setUser } = useContext(UserContext);



    useEffect(() => {
        async function fetchLogs() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/logs/reports`);
                url.searchParams.append('user', user.email);


                const response = await fetch(url, { method: 'POST' });
                const data = await response.json();
                setLogs(data);
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        }
        fetchLogs();
    }, []);

    return (
        <>
            <div className={s.forwardback} onClick={handleBackClick}>
                <div className={s.backIcon} >
                    <img src={'/forwardback.svg'}></img>
                </div>
                <div className={s.forwardbackText}>Назад</div>
            </div>

            <Table logs={logs} ></Table>
        </>
    )


};






export default LogsReport;
