import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../App';
import { getAddress } from '../../store/deviceSlice';
import s from "./AddressTree.module.css"


const TreeNode = ({ fullPath, children, setSelectedAddress, pathValues, setFullAddress }) => {
  const defaultOpenState = JSON.parse(localStorage.getItem(fullPath)) || false;
  const [isOpen, setIsOpen] = useState(defaultOpenState);

  const handleToggle = () => {
    localStorage.setItem(fullPath, JSON.stringify(!isOpen));
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    setFullAddress(pathValues);
  };

  const displayName = fullPath.split(', ').pop();

  return (
    <li>
      <div className={s.line}>
        <div className={`${s.caret} ${isOpen ? s.caretDown : ''}`} onClick={handleToggle}>
          {isOpen ? (
            <img src={"/arrowbackcaret.svg"} alt="Arrow back caret" />
          ) : (
            <img src={"/arrowright.svg"} alt="Arrow right" />
          )}
        </div>
        <a href='#' value={pathValues} onClick={handleClick}>
          {displayName}
        </a>
      </div>

      {isOpen && (
        <Tree
          data={children}
          setSelectedAddress={setSelectedAddress}
          pathValues={pathValues}
          setFullAddress={setFullAddress}
        />
      )}
    </li>
  );
};

const Tree = ({ data, isFirstLevel, fullPath, setSelectedAddress, pathValues = [], setFullAddress }) => {
  return (
    <ul className={isFirstLevel ? `${s.nested} ${s.active} ${s.treeline}` : `${s.nested} ${s.active}`}>
      {Object.keys(data).map(key => {
        const newPath = fullPath ? `${fullPath} > ${key}` : key;
        const currentPathValues = [...pathValues, key];
        return (
          <TreeNode
            key={newPath}
            name={key}
            fullPath={newPath}
            setSelectedAddress={setSelectedAddress}
            children={data[key]}
            setFullAddress={setFullAddress}
            pathValues={currentPathValues}
          />
        );
      })}
    </ul>
  );
};

const TreeDiv = ({ setSelectedAddress, handleFullAddressChange }) => {
  const [fullAddress, setFullAddress] = useState([]);
  handleFullAddressChange(fullAddress)
  return (
    <div>
      <AddressTree setSelectedAddress={setSelectedAddress} setFullAddress={setFullAddress} />
    </div>
  );
};

const AddressTree = ({ setSelectedAddress, setFullAddress }) => {
  const [treeData, setTreeData] = useState(null);
  const { user, setUser } = useContext(UserContext);  

  useEffect(() => {
    const createTree = async () => {
      let address = await getAddress(user);
      let tree = {};

      address.forEach(function (item) {
        const fullPath = `${item.country || ''}, ${item.region || ''}, ${item.city || ''}, ${item.street || ''}, ${item.building || ''}`;

        let pathArray = fullPath.split(', ');
        let currentNode = tree;

        pathArray.forEach((key) => {
          if (!currentNode[key]) {
            currentNode[key] = {};
          }
          currentNode = currentNode[key];
        });
      });
      setTreeData(tree);
    };

    createTree();
  }, []);

  return treeData ? <Tree data={treeData} isFirstLevel={true} fullPath="" setSelectedAddress={setSelectedAddress} setFullAddress={setFullAddress} /> : null;
};

export default TreeDiv;  