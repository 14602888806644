import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from "../../App";
import { DataGrid } from '@mui/x-data-grid';
import { Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../../src/i18n';
import { ruRU } from '@mui/x-data-grid/locales'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import s from "./SettingData.module.css"
import ModalTemplate from '../ModalForms/ModalForms';
import { CreateUserForm } from './Forms';
import { EditForm } from '../Forms/UserForm';


function UsersTable({ onMenuChange }) {
  const { user, setUser } = useContext(UserContext);
  const [editingEmail, setEditingEmail] = useState(null);
  const [data, setData] = useState([])
  const { t } = useTranslation();
  const [openUser, setOpenUser] = useState(false);
  const handleOpenUser = () => setOpenUser(true);
  const handleCloseUser = () => setOpenUser(false);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const handleOpenCreateUser = () => setOpenCreateUser(true);
  const handleCloseCreateUser = () => setOpenCreateUser(false);

  const theme = createTheme(
    ruRU
  );

  useEffect(() => {
    async function fetchData() {
      if (user && user.email) {
        try {
          const url = new URL(`${process.env.REACT_APP_API_URL}/users/list`);

          url.searchParams.append('email', user.email);

          let response = await fetch(url);
          let data = await response.text();
          let obj = JSON.parse(data);
          setData(obj)
        } catch (error) {
          console.error('Error fetching device:', error);
        }
      }
    }
    fetchData();
  }, [user]);

  const deleteUser = async (email) => {
    try {
      const url = new URL(`${process.env.REACT_APP_API_URL}/users/delete`);

      url.searchParams.append('email', email);

      let response = await fetch(url, {
        method: 'POST'
      });
      if (response.ok) {
        console.log("User deleted successfully");
      } else {
        throw new Error('Failed to delete the user');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  }

  const editUser = (email) => {
    if (!editingEmail) {
      setEditingEmail(email);
    }

  };

  const rows = data.map(item => ({
    id: item._id,
    email: item.email,
    role: t(item.role),
    fullname: item.fullname,
    phone: item.phone
  }))


  const columns = [
    { field: 'email', headerName: t('email'), width: 240 },
    { field: 'role', headerName: t('role'), width: 240 },
    { field: 'fullname', headerName: t('fullname'), width: 250 },
    { field: 'phone', headerName: t('phone'), width: 220 },
    {
      field: 'action',
      headerName: t('action'),
      width: 100,
      sortable: false,
      renderCell: (params) => (

        <div className={s.action}>
          <div className={s.actEditButton} onClick={(event) => {
            event.stopPropagation();
            editUser(params.row.email);
            handleOpenUser();
          }}>
            <EditIcon />
          </div>
          <div className={s.actDelButton} onClick={() => { deleteUser(params.row.email) }}>
            <DeleteIcon />
          </div>
        </div>
      ),
    },
  ];



  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%' }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{
            marginBottom: 1
          }}
          onClick={handleOpenCreateUser}
        >
          {t('createUser')}
        </Button>
        <ModalTemplate
          open={openCreateUser}
          onClose={handleCloseCreateUser}
        >
          <CreateUserForm onSuccess={handleCloseCreateUser}/>
        </ModalTemplate>
        <Box sx={{ width: '100%', overflowX: 'auto' }}>
          <DataGrid
            columns={columns}
            rows={rows}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            autoHeight
            pageSizeOptions={[10, 20, 50, 100]}
            sx={{
              "& p": {
                marginTop: "auto",
                marginBottom: "1rem",
              },
            }}
          />
        </Box>
        {editingEmail && (
          <ModalTemplate
            open={openUser}
            onClose={handleCloseUser}
          >
            <EditForm email={editingEmail} onSuccess={handleCloseUser} />
          </ModalTemplate>
        )}
      </Box>
    </ThemeProvider>
  )

}

export default UsersTable;

