import s from "./DevicesImport.module.css"
import * as Yup from 'yup';
import scanner from './scanner.png'
import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from "../../App";
import QRModal from "./QRModal";
import { useFormik } from 'formik';
import { TextField, Select, FormControl, InputLabel, MenuItem, Button, Autocomplete } from '@mui/material';
import ScanModal from "./ScanModal";
import ModalTemplate from "../ModalForms/ModalForms";
import { NbiotGroupForm } from "../Forms/NbiotForm";
import { AddressFormChirpStack, AddressFormNbiot } from "../Forms/AddressForm";
import { ApplicationForm } from "../Forms/ChirpStackForm";
// import CameraComponent from "../Camera/Camera";

const validationSchema = Yup.object({
    deveui: Yup.string()
        .matches(/^[0-9a-fA-F]{16}$/, "DevEUI must be a 16-character hexadecimal string.")
        .required("DevEUI is required."),
    appkey: Yup.string()
        .matches(/^[0-9a-fA-F]{32}$/, "AppKey must be a 32-character hexadecimal string.")
        .required("AppKey is required."),
});

const useHideMessage = (submitStatus, setSubmitStatus, setErrorMessage) => {
    useEffect(() => {
        if (submitStatus === 'success' || submitStatus === 'error') {
            const timer = setTimeout(() => {
                setSubmitStatus(null);
                setErrorMessage(null);
            }, 10000);
            return () => clearTimeout(timer);
        }
    }, [submitStatus, setSubmitStatus, setErrorMessage]);
};

export const DeviceForm = () => {
    const { user, setUser } = useContext(UserContext);
    const [addressList, setAddressList] = useState();
    const [scanActive, setScanActive] = useState(false);
    const [scanVolumeActive, setScanVolumeActive] = useState(false);
    const [activeField, setActiveField] = useState(null);
    const [qr, setQR] = useState(false);
    const [scan, setScan] = useState(false);
    const [filteredAddressList, setFilteredAddressList] = useState(addressList);
    const [addNew, setAddNew] = useState(false);


    const closeModal = () => {
        setScanActive(false)
        setScanVolumeActive(false)
    };

    const [openGroup, setOpenGroup] = useState(false);
    const handleOpenGroup = () => setOpenGroup(true);
    const handleCloseGroup = () => setOpenGroup(false);

    const [openAddress, setOpenAddress] = useState(false);
    const handleOpenAddress = () => setOpenAddress(true);
    const handleCloseAddress = () => setOpenAddress(false);

    // const [openCamera, setOpenCamera] = useState(false);
    // const handleOpenCamera = () => setOpenCamera(true);
    // const handleCloseCamera = () => setOpenCamera(false);

    const formik = useFormik({
        initialValues: {
            application: '',
            address: '',
            number: '',
            start_volume: '',
            deveui: '',
            appeui: '',
            appkey: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            try {

                const url = new URL(`${process.env.REACT_APP_API_URL}/device/create-device-cs`);
                url.searchParams.append('user', user.email);
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    const handleAddressSuccess = (newAddress) => {
        setAddNew(true);
        formik.setFieldValue('address', newAddress._id);
        handleCloseAddress();
    };

    // const handleCameraSuccess = () => {
    //     handleCloseCamera();
    // };

    useEffect(() => {
        async function fetchAddress() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/address/group`);
                user.cs_group.forEach(csGroup => {
                    url.searchParams.append('cs_group_id', csGroup._id);
                });
                let response = await fetch(url);
                const data = await response.json();
                setAddressList(data);
                setAddNew(false);
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        }
        fetchAddress();
    }, [addNew]);

    useEffect(() => {
        if (formik.values.cs_group) {
            const filteredAddresses = addressList.filter(addr =>
                addr.cs_group.some(group => group._id === formik.values.cs_group)
            );
            setFilteredAddressList(filteredAddresses);
        } else {
            setFilteredAddressList(addressList);
        }
    }, [formik.values.cs_group, addressList]);
    

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleButtonClick = (field) => {
        setActiveField(field);
        setQR(true);
        setScanActive(true);
    };

    const handleButtonClickScan = (field) => {
        setActiveField(field);
        setScan(true);
        setScanVolumeActive(true);
    };

    const handleGroupSuccess = (group) => {
        formik.setFieldValue('cs_group', group._id);
        handleCloseGroup();
    };

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}

            <FormControl fullWidth sx={{ marginTop: 1 }}>
                <InputLabel>Группа устройств Lora</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="cs_group"
                    name="cs_group"
                    label="Группа устройств Lora"
                    value={formik.values.cs_group}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                    <MenuItem value="new" onClick={handleOpenGroup}>
                        + Создать новую группу
                    </MenuItem>
                    {user.cs_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>
                <ModalTemplate
                    open={openGroup}
                    onClose={handleCloseGroup}
                >
                    <ApplicationForm onSuccess={handleGroupSuccess} />
                </ModalTemplate>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: 1 }}>
                <Autocomplete
                    value={(Array.isArray(filteredAddressList) &&
                        filteredAddressList.find(addr => addr._id === formik.values.address))}
                    onChange={(event, newValue) => {
                        if (newValue && newValue._id === 'new') {
                            handleOpenAddress();
                        } else {
                            formik.setFieldValue('address', newValue ? newValue._id : '');
                        }
                    }}
                    freeSolo
                    options={[{ _id: 'new', label: '+ Добавить адрес' }, ...(filteredAddressList || [])]}
                    getOptionLabel={(option) =>
                        option._id === 'new' ? '' :
                            option.label || `${option.country || ''}, ${option.region || ''}, ${option.city || ''}, ${option.street || ''}, ${option.building || ''}`
                    }
                    renderInput={(params) => <TextField {...params} label="Адрес" />}
                    renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                            {option.label
                                ? option.label
                                : `${option.country || ''}, ${option.region || ''}, ${option.city || ''}, ${option.street || ''}, ${option.building || ''}`}
                        </li>
                    )}
                />
                <ModalTemplate
                    open={openAddress}
                    onClose={handleCloseAddress}
                >   
                    <AddressFormChirpStack onSuccess={handleAddressSuccess} />
                </ModalTemplate>
            </FormControl>
            <TextField
                sx={{ marginTop: 1 }}
                fullWidth
                margin="normal"
                id="number"
                name="number"
                label="Номер квартиры"
                value={formik.values.number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <div className={s.inputQr}>
                <TextField
                    sx={{ marginTop: 1 }}
                    fullWidth
                    margin="normal"
                    id="start_volume"
                    name="start_volume"
                    label="Начальные показания"
                    value={formik.values.start_volume}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {/* <div className={s.qrDiv}>
                    <img onClick={() => handleOpenCamera()} className={s.img} src={scanner}></img>
                </div> */}
            </div>
            {/* <ModalTemplate
                open={openCamera}
                onClose={handleCloseCamera}
            >
                <CameraComponent onSuccess={handleCameraSuccess}></CameraComponent>
            </ModalTemplate> */}

            <div className={s.inputQr}>
                <TextField
                    sx={{ marginTop: 1 }}
                    fullWidth
                    margin="normal"
                    id="deveui"
                    name="deveui"
                    label="DevEUI"
                    value={formik.values.deveui}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >

                </TextField>
                <div className={s.qrDiv}>
                    <img onClick={() => handleButtonClick('deveui')} className={s.img} src={scanner}></img>
                </div>
            </div>
            <div className={s.inputQr}>
                <TextField
                    sx={{ marginTop: 1 }}
                    fullWidth
                    margin="normal"
                    id="appeui"
                    name="appeui"
                    label="AppEUI"
                    value={formik.values.appeui}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                </TextField>
                <div className={s.qrDiv}>
                    <img onClick={() => handleButtonClick('appeui')} className={s.img} src={scanner}></img>
                </div>
            </div>
            <div className={s.inputQr}>
                <TextField
                    sx={{ marginTop: 1 }}
                    fullWidth
                    margin="normal"
                    id="appkey"
                    name="appkey"
                    label="AppKey"
                    value={formik.values.appkey}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                </TextField>
                <div className={s.qrDiv}>
                    <img onClick={() => handleButtonClick('appkey')} className={s.img} src={scanner}></img>
                </div>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
            {scanActive && (
                <QRModal isOpen={!!qr} closeModal={closeModal} setFieldValue={formik.setFieldValue} setScanActive={setScanActive} activeField={activeField} />
            )}
            {/* {scanVolumeActive && (
                <ScanModal isOpen={!!scan} closeModal={closeModal} setFieldValue={formik.setFieldValue} setScanActive={setScanVolumeActive} activeField={activeField} />
            )} */}

        </form>
    );
}


export const DevicesImportForm = () => {
    const [addressList, setAddressList] = useState();
    const [filteredAddressList, setFilteredAddressList] = useState(addressList);
    const { user, setUser } = useContext(UserContext);
    const [addNew, setAddNew] = useState(false);

    const username = user.email;

    const [openGroup, setOpenGroup] = useState(false);
    const handleOpenGroup = () => setOpenGroup(true);
    const handleCloseGroup = () => setOpenGroup(false);

    const [openAddress, setOpenAddress] = useState(false);
    const handleOpenAddress = () => setOpenAddress(true);
    const handleCloseAddress = () => setOpenAddress(false);


    const formik = useFormik({
        initialValues: {
            cs_group: '',
            address: '',
            file: null,
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const formData = new FormData();
            if (values.file) {
                formData.append('file', values.file);
            }
            formData.append('application', values.cs_group);
            formData.append('address', values.address);

            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/device/create-device`);
                url.searchParams.append('user', username);
                let response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'X-Username': username,
                    },
                    body: formData
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    const handleAddressSuccess = (newAddress) => {
        setAddNew(true);
        formik.setFieldValue('address', newAddress._id);
        handleCloseAddress();
        
    };

    const handleGroupSuccess = (group) => {
        formik.setFieldValue('cs_group', group._id);
        handleCloseGroup();
    };

    useEffect(() => {
        async function fetchAddress() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/address/group`);
                user.cs_group.forEach(csGroup => {
                    url.searchParams.append('cs_group_id', csGroup._id);
                });

                let response = await fetch(url);
                const data = await response.json();
                setAddressList(data);
                setAddNew(false);
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        }
        fetchAddress();
    }, [addNew]);

    useEffect(() => {
        if (formik.values.cs_group) {
            console.log(addressList);
            console.log(formik.values.cs_group);
            
            const filteredAddresses = addressList.filter(addr =>
                addr.cs_group.some(group => group._id === formik.values.cs_group)
            );
            setFilteredAddressList(filteredAddresses);
        } else {
            setFilteredAddressList(addressList);
        }
    }, [formik.values.cs_group, addressList]);


    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);


    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств Lora</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="cs_group"
                    name="cs_group"
                    label="Группа устройств Lora"
                    value={formik.values.cs_group}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                    <MenuItem value="new" onClick={handleOpenGroup}>
                        + Создать новую группу
                    </MenuItem>
                    {user.cs_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>
                <ModalTemplate
                    open={openGroup}
                    onClose={handleCloseGroup}
                >   
                    <ApplicationForm onSuccess={handleGroupSuccess} />
                </ModalTemplate>
            </FormControl>

            <FormControl fullWidth sx={{ marginTop: 1 }}>
                <Autocomplete
                    value={(Array.isArray(filteredAddressList) &&
                        filteredAddressList.find(addr => addr._id === formik.values.address))}
                    onChange={(event, newValue) => {
                        if (newValue && newValue._id === 'new') {
                            handleOpenAddress();
                        } else {
                            formik.setFieldValue('address', newValue ? newValue._id : '');
                        }
                    }}
                    freeSolo
                    options={[{ _id: 'new', label: '+ Добавить адрес' }, ...(filteredAddressList || [])]}
                    getOptionLabel={(option) =>
                        option._id === 'new' ? '' :
                            option.label || `${option.country || ''}, ${option.region || ''}, ${option.city || ''}, ${option.street || ''}, ${option.building || ''}`
                    }
                    renderInput={(params) => <TextField {...params} label="Адрес" />}
                    renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                            {option.label
                                ? option.label
                                : `${option.country || ''}, ${option.region || ''}, ${option.city || ''}, ${option.street || ''}, ${option.building || ''}`}
                        </li>
                    )}
                />

                <ModalTemplate
                    open={openAddress}
                    onClose={handleCloseAddress}
                >
                    <AddressFormChirpStack onSuccess={handleAddressSuccess} />
                </ModalTemplate>
            </FormControl>
            <div className={s.inputField}>
                <input
                    accept=".xlsx, .xls, .csv"
                    id="file"
                    name="file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        formik.setFieldValue("file", event.target.files[0]);
                    }}
                />
                <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span">
                        Выберите файл
                    </Button>
                    {formik.values.file && (
                        <span className={s.fileName}>{formik.values.file.name}</span>
                    )}
                </label>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}


export const DevicesEditForm = () => {


    const { user, setUser } = useContext(UserContext);

    const formik = useFormik({
        initialValues: {
            file: null,
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const formData = new FormData();
            if (values.file) {
                formData.append('file', values.file);
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/device/edit-devices`, {
                    method: 'POST',
                    headers: {
                        'X-Username': user.username,
                    },
                    body: formData
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })


    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <div className={s.inputField}>
                <input
                    accept=".csv"
                    id="file"
                    name="file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        formik.setFieldValue("file", event.target.files[0]);
                    }}
                />
                <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span">
                        Выберите файл
                    </Button>
                    {formik.values.file && (
                        <span className={s.fileName}>{formik.values.file.name}</span>
                    )}
                </label>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}


export const DevicesNbiotImportForm = () => {
    const [addressList, setAddressList] = useState();
    const [filteredAddressList, setFilteredAddressList] = useState(addressList);
    const { user, setUser } = useContext(UserContext);
    const [addNew, setAddNew] = useState(false);

    const username = user.email;

    const [openGroup, setOpenGroup] = useState(false);
    const handleOpenGroup = () => setOpenGroup(true);
    const handleCloseGroup = () => setOpenGroup(false);

    const [openAddress, setOpenAddress] = useState(false);
    const handleOpenAddress = () => setOpenAddress(true);
    const handleCloseAddress = () => setOpenAddress(false);

    const formik = useFormik({
        initialValues: {
            nbiot_group: '',
            address: '',
            file: null,
        },
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            const formData = new FormData();
            if (values.file) {
                formData.append('file', values.file);
            }
            formData.append('application', values.nbiot_group);
            formData.append('address', values.address);
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/device/create-device-nbiot`);
                url.searchParams.append('user', username);
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'X-Username': username,
                    },
                    body: formData
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    const handleAddressSuccess = (newAddress) => {
        setAddNew(true);
        formik.setFieldValue('address', newAddress._id);
        handleCloseAddress();
    };

    const handleGroupSuccess = (group) => {
        formik.setFieldValue('nbiot_group', group._id);
        handleCloseGroup();
    };

    useEffect(() => {
        async function fetchAddress() {
            try {
                const url = new URL(`${process.env.REACT_APP_API_URL}/address/group`);
                user.nbiot_group.forEach(nbiotGroup => {
                    url.searchParams.append('nbiot_group_id', nbiotGroup._id);
                });

                let response = await fetch(url);
                const data = await response.json();
                setAddressList(data);
                setAddNew(false);
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        }
        fetchAddress();
    }, [addNew]);

    useEffect(() => {
        if (formik.values.nbiot_group) {
            const filteredAddresses = addressList.filter(addr =>
                addr.nbiot_group.some(group => group._id === formik.values.nbiot_group)
            );
            setFilteredAddressList(filteredAddresses);
        } else {
            setFilteredAddressList(addressList);
        }
    }, [formik.values.nbiot_group, addressList]);

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <FormControl fullWidth sx={{ marginTop: 2 }}>
                <InputLabel>Группа устройств NBIoT</InputLabel>
                <Select
                    fullWidth
                    margin="normal"
                    id="nbiot_group"
                    name="nbiot_group"
                    label="Группа устройств NBIoT"
                    value={formik.values.nbiot_group}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                    <MenuItem value="new" onClick={handleOpenGroup}>
                        + Создать новую группу
                    </MenuItem>
                    {user.nbiot_group.map(group => (
                        <MenuItem value={group._id} key={group._id}>{group.name}</MenuItem>
                    ))}
                </Select>
                <ModalTemplate
                    open={openGroup}
                    onClose={handleCloseGroup}
                >
                    <NbiotGroupForm onSuccess={handleGroupSuccess} />
                </ModalTemplate>
            </FormControl>  
            <FormControl fullWidth sx={{ marginTop: 1 }}>
                <Autocomplete
                    value={(Array.isArray(filteredAddressList) &&
                        filteredAddressList.find(addr => addr._id === formik.values.address))}
                    onChange={(event, newValue) => {
                        if (newValue && newValue._id === 'new') {
                            handleOpenAddress();
                        } else {
                            formik.setFieldValue('address', newValue ? newValue._id : '');
                        }
                    }}
                    freeSolo
                    options={[{ _id: 'new', label: '+ Добавить адрес' }, ...(filteredAddressList || [])]}
                    getOptionLabel={(option) =>
                        option._id === 'new' ? '' :
                            option.label || `${option.country || ''}, ${option.region || ''}, ${option.city || ''}, ${option.street || ''}, ${option.building || ''}`
                    }
                    renderInput={(params) => <TextField {...params} label="Адрес" />}
                    renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                            {option.label
                                ? option.label
                                : `${option.country || ''}, ${option.region || ''}, ${option.city || ''}, ${option.street || ''}, ${option.building || ''}`}
                        </li>
                    )}
                />

                <ModalTemplate
                    open={openAddress}
                    onClose={handleCloseAddress}
                >
                    <AddressFormNbiot onSuccess={handleAddressSuccess} />
                </ModalTemplate>
            </FormControl>
            <div className={s.inputField}>
                <input
                    accept=".xlsx, .xls, .csv"
                    id="file"
                    name="file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        formik.setFieldValue("file", event.target.files[0]);
                    }}
                />
                <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span">
                        Выберите файл
                    </Button>
                    {formik.values.file && (
                        <span className={s.fileName}>{formik.values.file.name}</span>
                    )}
                </label>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>

    );
}

export const DevicesNbiotEditForm = () => {


    const { user, setUser } = useContext(UserContext);

    const formik = useFormik({
        initialValues: {
            file: null,
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const formData = new FormData();
            if (values.file) {
                formData.append('file', values.file);
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/device/edit-device-nbiot`, {
                    method: 'POST',
                    headers: {
                        'X-Username': user.username,
                    },
                    body: formData
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    setErrorMessage(errorText);
                    setSubmitStatus('error');
                    return;
                }

                const data = await response.json();
                setSubmitStatus('success');
                resetForm();
            } catch (error) {
                console.error('Error submitting form:', error.message);
            }
        }
    })

    const [submitStatus, setSubmitStatus] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useHideMessage(submitStatus, setSubmitStatus, setErrorMessage);

    return (
        <form onSubmit={formik.handleSubmit}>
            {submitStatus === 'success' && <div className={s.successMessage}>Форма успешно отправлена!</div>}
            {submitStatus === 'error' && <div className={s.errorMessage}>{errorMessage}</div>}
            <div className={s.inputField}>
                <input
                    accept=".csv"
                    id="file"
                    name="file"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(event) => {
                        formik.setFieldValue("file", event.target.files[0]);
                    }}
                />
                <label htmlFor="file">
                    <Button variant="contained" color="primary" component="span">
                        Выберите файл
                    </Button>
                    {formik.values.file && (
                        <span className={s.fileName}>{formik.values.file.name}</span>
                    )}
                </label>
            </div>
            <Button
                color="primary"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Отправить
            </Button>
        </form>
    );
}