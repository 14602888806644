import React from 'react';
import SettingDataContent from '../SettingData/SettingDataContent';
import s from "./SettingsContent.module.css"

const SettingsContent = ({ selectedMenu, handleMenuChange }) => {
    return (
        <div className={s.main}>
            {selectedMenu !== null &&
                <div className={s.forms}>
                    <SettingDataContent selectedMenu={selectedMenu} onMenuChange={handleMenuChange} />
                </div>
            }
        </div>
    )
}
export default SettingsContent;