import { Snackbar, Alert, AlertColor, SnackbarCloseReason } from '@mui/material';


export function showAccessAlert() {
    alert('У вас нет доступа для просмотра данной информации. За получением расширенных прав обратитесь пожалуйста к администратору');
  }


export function AlertBar({ open, handleClose, message, severity = 'info' }) {
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        handleClose(event, reason);
    };

    const handleAlertClose = (event) => {
        handleClose(event);
    };
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert onClose={handleAlertClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

